import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Layout from '../components/layout';

const styles = theme => ({
    section: {
        marginTop: theme.spacing.unit * 3
    },
    toolbar: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
    button: {
        marginRight: theme.spacing.unit,
        marginTop: 2,
        marginBottom: 2,
    }
});

const SectionSpacer = () => <div style={{ height: 30 }} />;

const IndexPage = ({ classes }) => (
    <Layout>
        <Typography variant="display3" gutterBottom>
            Hi!{' '}
            <span role="img" aria-label="(waving at you)">
                👋
            </span>{' '}
            I'm Florian.
        </Typography>
        <Typography>
            Welcome to my personal website. You'll mostly find links to what
            I've done or like.
        </Typography>

        <SectionSpacer />

        <Typography variant="display1" gutterBottom>
            Projects, apps and games
        </Typography>
        <section className={classes.section}>
            <Typography variant="title" gutterBottom>
                GDevelop
            </Typography>
            <Typography>
                GDevelop is an open-source, cross-platform game creator designed
                to be used by everyone - no programming skills required.
            </Typography>
            <div className={classes.toolbar}>
                <a href="https://gdevelop-app.com">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Website
                    </Button>
                </a>
                <a href="https://github.com/4ian/GDevelop">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        GitHub
                    </Button>
                </a>
                <a href="https://editor.gdevelop-app.com">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Try it online
                    </Button>
                </a>
            </div>
            <Typography>
                Written in C++ and JavaScript. Built with React.js, Emscripten,
                Pixi.js.
            </Typography>
        </section>
        <section className={classes.section}>
            <Typography variant="title" gutterBottom>
                Lil BUB's HELLO EARTH
            </Typography>
            <Typography>
                Lil BUB’s HELLO EARTH is a truly beautiful, smart, captivating
                and enchanting mobile retro-style 8-bit video game. And I built
                it with GDevelop :D
            </Typography>
            <div className={classes.toolbar}>
                <a href="https://itunes.apple.com/gb/app/lil-bubs-hello-earth/id1123383033?mt=8">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        iOS
                    </Button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.lilbub.game&hl=en_GB">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Android
                    </Button>
                </a>
                <a href="http://compilgames.net/bub-landing-page">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                    >
                        Windows/macOS/Linux
                    </Button>
                </a>
                <a href="https://www.kickstarter.com/projects/iamlilbub/lil-bubs-hello-earth-a-retro-8-bit-mobile-video-ga">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Kickstarter
                    </Button>
                </a>
            </div>
            <Typography>
                The kickstarter for the game raised $148003 and the game was
                released for all platforms from a single codebase.
            </Typography>
        </section>

        <SectionSpacer />

        <Typography variant="display1" gutterBottom>
            Talks
        </Typography>
        <section className={classes.section}>
            <Typography variant="title" gutterBottom>
                2019
            </Typography>
            <Typography variant="subheading" gutterBottom>
                Native Web Apps: React and WebAssembly to Rewrite Native Apps
            </Typography>
            <div className={classes.toolbar}>
                <a href="https://typeofconf.com/">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Typeof Conf (Porto)
                    </Button>
                </a>
                <a href="https://2019.codefest.ru/lecture/1425">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        CodeFest 2019
                    </Button>
                </a>
                <a href="https://react.amsterdam">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        React Amsterdam
                    </Button>
                </a>
                <a href="https://2019.jsconf.us/">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        JS Conf US
                    </Button>
                </a>
                <a href="https://intersection-conference.eu/">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Intersection Conference
                    </Button>
                </a>
                <a href="https://reactiveconf.com/">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Reactive Conf
                    </Button>
                </a>
            </div>
            <Typography variant="title" gutterBottom>
                2018
            </Typography>
            <Typography variant="subheading" gutterBottom>
                Beyond Web-Apps: React, Javascript and WebAssembly to Port
                Legacy Native Apps
            </Typography>
            <div className={classes.toolbar}>
                <a href="https://www.youtube.com/watch?v=6La7jSCnYyk">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        React Boston 2018 (Video)
                    </Button>
                </a>
                <a href="https://www.youtube.com/watch?v=EdTLGUU4r-k">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        React Conf 2018 (Video)
                    </Button>
                </a>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                >
                    React Next 2018
                </Button>
            </div>
            <Typography variant="subheading" gutterBottom>
                Working 30 months with 30 developers and React Native
            </Typography>
            <div className={classes.toolbar}>
                <a href="https://www.youtube.com/watch?v=LQOaOpaFClk">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Tech Power summit 2018 by STX Next
                    </Button>
                </a>
            </div>
            <Typography variant="title" gutterBottom>
                2017
            </Typography>
            <Typography variant="subheading" gutterBottom>
                Bridges to React Native
            </Typography>
            <Typography>(Warning, strong french accent ahead!)</Typography>
            <div className={classes.toolbar}>
                <a href="https://www.youtube.com/watch?v=k9qV73E3B_k">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        React Next 2017 (video)
                    </Button>
                </a>
                <a href="https://www.youtube.com/watch?v=IVrhuJdrS7c">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        React Native EU 2017 (video)
                    </Button>
                </a>
            </div>
            <Typography variant="subheading" gutterBottom>
                Cross-platform games creation with JavaScript
            </Typography>
            <div className={classes.toolbar}>
                <a href="https://slides.com/florianrival/mobile-games-16#/">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        GameCamp France (slides)
                    </Button>
                </a>
            </div>
            <Typography variant="title" gutterBottom>
                2016
            </Typography>
            <Typography variant="subheading" gutterBottom>
                From web to mobile
            </Typography>
            <div className={classes.toolbar}>
                <a href="https://www.youtube.com/watch?v=ELumPRYUR94">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Best of Web 2016 (video, in French)
                    </Button>
                </a>
            </div>
        </section>

        <SectionSpacer />

        <Typography variant="display1" gutterBottom>
            Find me on the web
        </Typography>
        <section className={classes.section}>
            <div className={classes.toolbar}>
                <a href="https://twitter.com/florianrival">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        Twitter
                    </Button>
                </a>
                <a href="https://linkedin.com/in/florianrival/">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        LinkedIn
                    </Button>
                </a>
                <a href="https://github.com/4ian">
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                    >
                        GitHub
                    </Button>
                </a>
            </div>
        </section>

        <SectionSpacer />

        <Typography variant="display1" gutterBottom>
            Find me in London
        </Typography>
        <Typography>
            I currently live in London. Always up to grab a beer!{' '}
            <span role="img" aria-label="beer with you?">
                🍻
            </span>
        </Typography>

        <SectionSpacer />
    </Layout>
);

export default withStyles(styles)(IndexPage);
